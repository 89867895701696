/* ensure all pages have Bootstrap CSS */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import 'open-iconic-bootstrap.min.css';

/* tailwind setup */
@tailwind base;
@tailwind components;
@tailwind utilities;

.subcat-link {
  cursor: pointer;
}

.subcat-link:hover {
  text-decoration: underline;
}

.table-grid-container > div {
  max-height: 100%;
}

.edit-product {
  position: fixed;
  bottom: 0px;
  max-height: none;
  z-index: 0;
  margin: 0 0px 0 65px;
  width: 97%;
  background: #ffffff;
  left: 0;
  text-align: right;
  border-top: 1px solid #ddd;
  padding: 10px;
  justify-content: end;
  padding-right: 80px;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.6);
}

.loading-shading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background: rgba(255, 255, 255, 0.8);
}

.loading-icon {
  position: absolute;
  font-size: 25px;
  top: calc(45% - 10px);
  left: calc(50% - 10px);
  animation: spin 0.7s infinite linear;
}

@keyframes spin {
  from {
    transform: scale(1) rotate(360deg);
  }
  to {
    transform: scale(1) rotate(0deg);
  }
}

.btn-secondary {
  color: #222;
  border-color: #6c757d !important;
}
a:hover {
  text-decoration: none;
}

.width-screen.col-md-11 {
  padding-left: 185px;
}

.width-screen.col-md-1 {
  position: fixed;
  left: 65px;
  z-index: 9;
}

.width-screen.edit-product {
  position: fixed;
  bottom: 0px;
  max-height: none;
  z-index: 1;
  margin: 0 0% 0 65px;
  width: 100%;
  background: #ffffff;
  left: 0;
  text-align: right;
  border-top: 1px solid #ddd;
  padding: 10px;
  justify-content: end;
  padding-right: 80px;
  box-shadow: 3px 5px 0px -1px rgba(0, 0, 0, 0.6);
}

.open-arrow-fix {
  position: fixed;
  bottom: 15px;
  z-index: 7;
  left: 90px;
}

@media screen and (max-width: 1680px) {
  .width-screen.col-md-11 {
    flex: 0 0 95.666667%;
    max-width: 95.666667%;
    padding-left: 60px;
  }

  .width-screen.col-md-1 {
    flex: 0 0 4.333333%;
    max-width: 4.333333%;
    position: fixed;
    left: 65px;
    z-index: 7;
  }
  .width-screen.edit-product {
    margin: 0 0% 0 9.4%;
    width: 94%;
    padding-right: 80px;
    box-shadow: 3px 5px 0px -1px rgba(0, 0, 0, 0.6);
  }
}

.list-group-item.active {
  background-color: #1e3a8a;
  border-color: #1e3a8a;
}
.list-group-item.active img {
  filter: invert(7) brightness(13);
}

.expended .width-screen.col-md-1 {
  left: 245px;
}

@media screen and (min-width: 768px) {
  .col-md-11 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.col-md-1 {
  flex: 0 0 4.333333%;
  max-width: 4.333333%;
}

.col-md-9 {
  flex: 0 0 79%;
  max-width: 79%;
}

.col-md-10 {
  flex: 0 0 91.333333%;
  max-width: 91.333333%;
}

.vh-100 {
  height: 94.8vh !important;
}

.bottom-arrow-position {
  z-index: 999;
}
.btn,
.dropdown-item {
  font-size: 14px !important;
  font-weight: 500 !important;
}
.dropdown-item.org-switch-item {
  @apply flex justify-between px-4 py-3 my-2 text-sm text-gray-700 border-t border-b;
}
.dropdown-item.logout-item {
  @apply !py-3 border-[#e5e7eb] border-solid border-t;
}
.inbox-widget .dropdown-item:hover {
  color: #0c124a;
  text-decoration: none;
  background-color: #ecf1f5 !important;
  border-radius: 4px;
  font-weight: 600 !important;
}
.btn-success {
  background-color: #1abc9c !important;
  border-color: #1abc9c !important;
  color: #ffffff !important;
}

.btn-success:hover {
  background-color: #159a80 !important;
  border-color: #148f77 !important;
}

@media screen and (max-width: 1680px) {
  .col-md-1 {
    flex: 0 0 6.333333%;
    max-width: 6.333333%;
  }

  .col-md-9 {
    flex: 0 0 77%;
    max-width: 77%;
  }

  .col-md-10 {
    flex: 0 0 87.333333%;
    max-width: 87.333333%;
  }

  .vh-100 {
    height: 91.5vh !important;
  }
  .custom-scroll {
    max-height: 70%;
    overflow-y: auto;
  }
}

.margin-m-3 {
  margin: 0px -3px;
}
/* Hiding Pivot Mode in Sidebar's Columns */
.ag-pivot-mode-panel > div {
  display: none !important;
}
/* Hiding Row Groups in Sidebar's Columns */
.ag-column-drop:has(.ag-icon-group) {
  display: none !important;
}
/* Hiding Values in Sidebar's Columns */
.ag-column-drop:has(.ag-icon-aggregation) {
  display: none !important;
}
.ag-tool-panel-wrapper {
  width: 360px !important;
}
.ag-virtual-list-container.ag-column-select-virtual-list-container {
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.ag-popup-editor {
  width: 250px !important;
}
/* react dropdown tree select CSS */
.react-dropdown-tree-select .dropdown {
  @apply w-full;
}
.ag-popup-editor .react-dropdown-tree-select {
  .dropdown {
    @apply bg-white;
  }
  .dropdown-trigger {
    @apply !border-none;
  }
  .dropdown-content {
    @apply mt-2;
  }
}
.react-dropdown-tree-select .dropdown-trigger {
  @apply !cursor-default rounded-[0.25rem] border !border-gray-300 w-full hover:text-black  shadow-none py-1 px-2 hover:!border-gray-400 transition-all duration-200 outline-none focus:ring-[2px] focus:ring-purple-500 !pr-7;
}
.react-dropdown-tree-select .dropdown-content {
  @apply rounded-[0.25rem] border !border-gray-300 overflow-y-auto !px-2 !py-2 w-full max-h-[290px];
}
.react-dropdown-tree-select .dropdown .dropdown-content .search {
  @apply pb-2;
}
.react-dropdown-tree-select .dropdown-content .no-matches {
  @apply text-gray-400 mt-1 block !text-center;
}
.react-dropdown-tree-select .tag {
  @apply py-0 pl-1;
}
.react-dropdown-tree-select .node-label {
  @apply pointer-events-none select-none text-sm;
}
.react-dropdown-tree-select .tag-item .tag {
  @apply rounded-[0.25rem] py-[3px] pl-[10px] bg-[#00e5ff30] text-[#1437a0] border border-[#00e5ff] text-sm font-medium;
}
.react-dropdown-tree-select .tag-remove {
  @apply !text-base !px-2 !py-1 font-semibold text-[#1437a0] shadow-none;
}
.react-dropdown-tree-select .tag-remove:hover {
  @apply bg-[#FFBDAD] text-[#DE350B];
}
.react-dropdown-tree-select .tag-item:has(.tag) {
  @apply !m-[1px];
}
.react-dropdown-tree-select .tag .tag-remove {
  @apply text-lg ml-[1px] p-1 hover:bg-red-400/20 hover:text-gray-700 !leading-none;
}
.react-dropdown-tree-select .node {
  @apply flex items-center;
}
.react-dropdown-tree-select .node i {
  @apply ml-1 !text-[1.5rem] leading-none;
}
.react-dropdown-tree-select .node label {
  @apply mb-0 w-full;
}
.react-dropdown-tree-select .placeholder {
  @apply text-gray-400 select-none;
}
.react-dropdown-tree-select .dropdown .dropdown-trigger.arrow:after {
  @apply !hidden;
}
.react-dropdown-tree-select .toggle {
  font-style: normal;
  @apply text-gray-300 hover:bg-orange-50 transition-colors duration-200;
}
.react-dropdown-tree-select .toggle.expanded:after {
  content: '\25B4';
}
.react-dropdown-tree-select .toggle.collapsed:after {
  content: '\25BE';
}
/* dropdown tree select CSS */

/* input type radio CSS */
.radio-input {
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  font-size: 0.875rem;
  user-select: none;
}

/* Hide the browser's default checkbox */
.radio-input {
  color: #6c757d;
}

.radio-input input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 50%;
  border: 2px solid #98a6ad;
  transition: border .5s ease-in-out;
  outline: 0 !important;
  z-index: 10;
}

.radio-input:hover input ~ .checkmark {
  background-color: #e4e2e2;
}

/* When the checkbox is checked, add a blue background */
.radio-input input:checked ~ .checkmark {
  background-color: #fff;
}

.radio-input input:checked ~ .checkmark::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: #28b5a9;
  border-color: #28b5a9;
}

/* input type radio CSS */

/* quill editor CSS */
.ql-toolbar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.ql-formats:not(:last-child) {
  margin-right: 3px !important;
  border-right: 1px solid rgb(150, 159, 168);
}
.ql-container{
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.ql-editor{
  height: 151px;
  max-height: 151px;
  overflow: auto;
}
.ql-editor:focus {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  @apply ring-2 ring-[#2684FF] transition-all duration-100;
}
/* quill editor CSS */
/* notification CSS */
.notification-wrapper {
  cursor: pointer;
}
.notification-wrapper:hover {
  opacity: 0.9;
  transition: all 1s linear;
}
.notification-badge {
  position: absolute;
  top: -8px;
  right: -10px;
  background-color: #1e3a8a;
  color: #fff;
  font-size: 0.7rem;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* notification CSS */

/* react select CSS */
.react-select__menu {
  cursor: pointer;
  @apply !w-full !max-w-sm;
}
.react-select__control {
  padding: 0 6px;
  @apply !w-full !max-w-xs;
}
.react-select__control:hover {
  @apply hover:ring-1 hover:ring-[#98a6ad];
  cursor: text;
}
.react-select__control.react-select__control--is-focused {
  border-color: #98a6ad !important;
  box-shadow: 0 0 0 1px #98a6ad !important;
}
.react-select__option {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-weight: normal;
  margin: 4px 0;
  padding: 4px 6px;
}
.react-select__option:hover {
  background-color: #f5f5f5;
}
/* react select CSS */
.calendar-round-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid #148f77;
  border-radius: 50%;
}
.react-datepicker-wrapper {
  flex: 1 !important;
}
.react-datepicker__close-icon::after {
  background-color: transparent;
  color: hsl(0, 0%, 80%);
  font-size: 2rem;
}
.status-chip-wrapper {
  max-width: 100%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  height: 32px;
  color: rgb(255, 255, 255);
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: unset;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 0px;
  vertical-align: middle;
  box-sizing: border-box;
}
.status-chip-text {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 12px;
  padding-right: 12px;
  white-space: nowrap;
}
.status-default {
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.08);
}
.status-warning {
  color: rgba(0, 0, 0, 0.87);
  background-color: #f4f429;
}
.status-success {
  background-color: #008000;
}
.status-danger {
  background-color: #ff0000;
}

.staging-data-grid {
  height: 90% !important;
  width: 100% !important;
}
.staging-data-grid.footer-edit {
  height: calc(90% - 60px) !important;
  width: 100% !important;
}

.react-select__option {
  color: #000 !important;
  @apply !text-xs drop-shadow-sm;
}

.product__search-facet-filter {
  max-width: 100%;
  font-size: 0.8125rem;
  display: inline-flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  column-gap: 4px;
  border-radius: 16px;
  white-space: nowrap;
  transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  cursor: unset;
  outline: 0px;
  text-decoration: none;
  border: 0px;
  padding: 6px 10px;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0);
  background-color: rgba(0, 0, 0, 0.08);
}